import React from 'react';

interface Props {
  id: string;
  label: string;
  options: { key: string; value: string; disabled?: boolean }[];
  placeholder?: string;
  required?: boolean;
  styles?: string;
  error?: any;
  disabled?: boolean;
  onSelect?: (value: string) => void;
}

export const SelectField = React.forwardRef<HTMLSelectElement, Props>(
  (
    { id, label, options, required, styles, placeholder, error, disabled, onSelect, ...rest },
    ref
  ) => (
    <label htmlFor={id} className="flex flex-col gap-0.5">
      <span className="text-sm text-[#333333]">
        {label} {required && '*'}
      </span>
      <div className="relative">
        <select
          id={id}
          name={id}
          className={[
            'h-[50px] appearance-none rounded-lg border border-[#BDBDBD] p-2 pr-10 text-[#828282] transition-all duration-300 focus:border-[#2B303A] focus:text-[#2B303A] focus:shadow-[0px_0px_0px_4px_#EEE5E9] focus:outline-none disabled:pointer-events-none disabled:opacity-50',
            styles,
          ].join(' ')}
          required={required}
          disabled={disabled}
          ref={ref}
          defaultValue={options[0].value}
          onChange={e => {
            e.preventDefault();
            !!onSelect && onSelect(e.currentTarget.value);
          }}
          {...rest}
        >
          {options.map(option => (
            <option
              value={option.value}
              key={option.key}
              className="text-[#828282]"
              disabled={option.disabled}
            >
              {option.key}
            </option>
          ))}
        </select>

        <div className="pointer-events-none absolute right-3 top-1/2 flex h-[15px] w-[15px] -translate-y-1/2 items-center justify-center rounded-sm bg-[#828282]">
          <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="0.5" width="15" height="15" rx="4" fill="#2B303A"></rect>
            <rect
              x="3.0809"
              y="5"
              width="8"
              height="2"
              rx="1"
              transform="rotate(45 3.0809 5)"
              fill="white"
            ></rect>
            <rect
              x="12.9804"
              y="6.41418"
              width="8"
              height="2"
              rx="1"
              transform="rotate(135 12.9804 6.41418)"
              fill="white"
            ></rect>
          </svg>
        </div>
      </div>
      {error && <span className="text-sm text-red-500">{error.message}</span>}
    </label>
  )
);
