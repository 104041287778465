import { useSelector } from 'react-redux';

import { LinkButton } from '@components/Button';
import { ManualTask } from '@components/ManualTask';
import { Map } from '@components/Map';
import { Product } from '@components/Product';
import {
  BuyerProtectionSignpost,
  ConciergeReminderSignpost,
  ConveyancingSignpost,
  InsuranceReminderSignpost,
  InsuranceSignpost,
  MortgageSignpost,
  Signpost,
} from '@components/Signpost';

import { selectProfile } from '@features/profile/profile';

import BundleLogos from '@images/bundles-logos.png';
import BundlesIcons from '@images/bundles_icons.svg';
import EnterpriseLogo from '@images/enterprise_logo.webp';
import EnterpriseVan from '@images/enterprise_van.webp';
import EverythingToMakeItHome from '@images/everything_to_make_it_home.webp';
import {
  Bulb,
  Conveyancing as ConveyancingIcon,
  Insurance as InsuranceIcon,
  MapPin,
  Payment,
  Pound,
  Removals as RemovalsIcon,
  TickIsolated,
} from '@images/icons';
import ProductRemovalPack from '@images/products/product_removal-pack.webp';

import { convertAddressToSingleLine } from '@utils/convertAddress';

import { Broadband } from './components/Broadband';
import { Energy } from './components/Energy';

const ContentBlock = ({ title, content, link, buttonText }: any) => (
  <div className="flex w-full max-w-xl flex-col gap-4">
    <h4 className="text-2xl font-medium">{title}</h4>
    <p className="text-lg font-light">{content}</p>
    <a href={link} target="_blank" className="underline hover:no-underline" rel="noreferrer">
      {buttonText}
    </a>
  </div>
);

const FindAProperty = () => {
  const { value: profile } = useSelector(selectProfile);

  const postcode = profile?.properties[0]?.postcode || '';
  const address = profile?.properties?.[0] ? convertAddressToSingleLine(profile.properties[0]) : '';

  return (
    <>
      <div className="flex flex-col items-center gap-10 px-7 pb-12 md:grid md:grid-cols-2 md:gap-14 md:pb-8 md:pt-0">
        <div className="flex w-full max-w-xl flex-col gap-5">
          {postcode && (
            <div className="block h-40 w-full overflow-hidden rounded-xl">
              <Map postcode={postcode} />
            </div>
          )}
          <span className="font-heading text-2xl font-medium">£688,780</span>
          <h4 className="text-xl font-medium">{address}</h4>
        </div>
        <hr className="w-full border-stone-200 md:hidden" />
        <BuyerProtectionSignpost />
      </div>
      <div className="bg-why flex flex-col gap-4 p-7 pb-8">
        <h4 className="text-2xl font-medium">My check list</h4>
        <ManualTask uiName="Sale agreed" />
      </div>
    </>
  );
};

const Conveyancing = () => (
  <>
    <div className="flex flex-col items-center gap-10 px-7 pb-12 md:grid md:grid-cols-2 md:gap-14 md:pb-8 md:pt-0">
      <ConveyancingSignpost />
      <hr className="w-full border-stone-200 md:hidden" />
      <ContentBlock
        title="What to expect"
        content="With a conveyancer or solicitor on your side, you can feel confident you won't get any nasty surprises down the line. Be prepared for the conveyancing process to take several weeks, depending on the complexity of the sale."
        link="/"
        buttonText="Read our guide to conveyancing"
      />
    </div>
    <div className="bg-why flex flex-col gap-4 p-7 pb-8">
      <h4 className="text-2xl font-medium">My check list</h4>
      <ManualTask uiName="Instruct a conveyancer" />
    </div>
  </>
);

const Finances = () => (
  <>
    <div className="flex flex-col gap-10 px-7 pb-12 md:grid md:grid-cols-2 md:gap-14 md:pb-8 md:pt-0">
      <MortgageSignpost />
      <hr className="w-full border-stone-200 md:hidden" />
      <div className="flex flex-col items-center gap-8">
        <ContentBlock
          title="Mortgage rate checker"
          content="Work out what size of mortgage you could afford and how much mortgage repayments are for different loans"
          link="/"
          buttonText="Use the calculator"
        />
        <ContentBlock
          title="What to expect"
          content="When you're buying a home, the mortgage process can seem complex and overwhelming, but it doesn't have to be with a mortgage broker on hand to guide you through the process."
          link="/"
          buttonText="Read our guide to the mortgage process"
        />
      </div>
    </div>
    <div className="bg-why flex flex-col gap-4 p-7 pb-8">
      <h4 className="text-2xl font-medium">My check list</h4>
      <ManualTask uiName="Instruct a broker" />
      <ManualTask uiName="Apply for a mortgage" />
      <ManualTask uiName="Sign mortgage offer" />
    </div>
  </>
);

const Insurance = () => (
  <>
    <div className="flex flex-col gap-10 px-7 pb-12 md:grid md:grid-cols-2 md:gap-14 md:pb-8 md:pt-0">
      <InsuranceSignpost />
      <hr className="w-full border-stone-200 md:hidden" />
      <div className="flex flex-col items-center gap-8">
        <ContentBlock
          title="What to expect"
          content="If you're purchasing a new home too you will need to make sure you have buildings insurance in place on your new property."
          link="/"
          buttonText="Read our guide to insurance"
        />
      </div>
    </div>
    <div className="bg-why flex flex-col gap-4 p-7 pb-8">
      <h4 className="text-2xl font-medium">My check list</h4>
      <ManualTask uiName="Get covered" />
    </div>
  </>
);

const Exchange = () => (
  <>
    <div className="flex flex-col gap-10 px-7 pb-12 md:grid md:grid-cols-2 md:gap-14 md:pb-8 md:pt-0">
      <div className="flex flex-col items-center gap-8">
        <ContentBlock
          title="What to expect"
          content="This is where it becomes official. Your solicitor and the seller's solicitor will swap signed copies of the contract. This makes it a legally-binding contract and you really start to celebrate. Read our guide to exchanging contracts"
          link="/"
          buttonText="Read our guide to exchanging contracts"
        />
      </div>
      <hr className="w-full border-stone-200 md:hidden" />
      <InsuranceReminderSignpost />
    </div>
    <div className="bg-why flex flex-col gap-4 p-7 pb-8">
      <h4 className="text-2xl font-medium">My check list</h4>
      <ManualTask uiName="Exchange contracts" />
    </div>
  </>
);

const Removals = () => (
  <>
    <div className="flex flex-col gap-10 px-7 pb-12 md:grid md:grid-cols-2 md:gap-14 md:pb-8 md:pt-0">
      <Signpost
        subtitle="To do"
        title="Book your removals"
        text="Home's got lorry loads of choice. Pick one of our reliable partners, and book them in. Easy."
      >
        <div>
          <div className="mt-8 flex flex-col items-center gap-5 border-t border-stone-200 py-10">
            <LinkButton href="/removals" target="_blank">
              Book my removal
            </LinkButton>
          </div>
          <div className="flex flex-col items-center gap-5 border-t border-stone-200 py-10">
            <h4 className="text-center text-2xl font-medium">Looking for van hire instead?</h4>
            <div className="shadow-homeLargeLight flex flex-col items-center gap-0 rounded-lg border border-stone-200 p-5 md:p-6">
              <img
                src={EnterpriseVan}
                alt="Enterprise Van"
                className="w-full max-w-[266px] object-cover"
              />
              <h5 className="mb-6 text-center text-xl font-medium">
                15% off Van hire at Enterprise
              </h5>
              <div className="flex w-full items-center justify-around gap-5">
                <img src={EnterpriseLogo} alt="Enterprise" className="w-44" />
                <a
                  href="/"
                  target="_blank"
                  className="rounded-md bg-black px-5 py-1.5 font-medium text-white"
                >
                  See prices
                </a>
              </div>
            </div>
            <a href="/" className="text-center underline hover:no-underline">
              View packing supplies in HomeStore
            </a>
          </div>
        </div>
      </Signpost>
      <hr className="w-full border-stone-200 md:hidden" />
      <div className="flex flex-col items-center gap-14">
        <ContentBlock
          title="Why use a Home partner?"
          content="Make your move hassle-free with professional moving services. Our reliable partners offer simple 'man and van' services through to a white glove approach with everything packed for you and unpacked in your new home. Read our guide to removals"
          link="/"
          buttonText="Read our guide to removals"
        />
        <hr className="w-full border-stone-200" />
        <div className="flex w-full max-w-md flex-col items-center gap-8">
          <Product
            title="Packing supplies kit (3 bed)"
            image={ProductRemovalPack}
            price={46.99}
            link="https://store.home.cc/products/1-2-bedroom-removal-pack-25-boxes"
          />
          <a href="/" className="text-center underline hover:no-underline">
            View packing supplies in HomeStore
          </a>
        </div>
      </div>
    </div>
    <div className="bg-why flex flex-col gap-4 p-7 pb-8">
      <h4 className="text-2xl font-medium">My check list</h4>
      <ManualTask uiName="Book a removal" />
    </div>
  </>
);

const GetMoveReady = () => {
  const { value: profile } = useSelector(selectProfile);

  const postcode = profile?.properties[0]?.postcode || '';

  return (
    <>
      <div className="flex flex-col items-start gap-10 px-7 pb-12 md:grid md:grid-cols-2 md:gap-14 md:pb-8 md:pt-0">
        <Signpost
          subtitle="To do"
          title="Set up your Home"
          text="Home makes getting everything ready for move in day a breeze saving you hours of hassle. Create a bundle for one easy bill or set up individually to suit you."
        >
          <div className="mt-8 ">
            <div className="flex flex-col items-center gap-5 border-t border-stone-200 py-10">
              <Energy postcode={postcode} />
            </div>
            <div className="flex flex-col items-center gap-5 border-t border-stone-200 py-10">
              <Broadband postcode={postcode} />
            </div>
          </div>
        </Signpost>
        <hr className="w-full border-stone-200 md:hidden" />
        <div className="flex flex-col items-center gap-14">
          <div className="w-full">
            <div className="flex w-full flex-col items-center gap-5 py-10">
              <img src={BundlesIcons} alt="Bundles" className="w-full max-w-[266px] object-cover" />
              <h4 className="max-w-sm text-center text-3xl font-medium">
                Save time and money with a Home bills bundle
              </h4>
              <p className="max-w-sm text-center">
                One payment, all your bills sorted. Create a bundle of all your bills to save more
                and simplify your home admin with just one easy bill to manage. Living with other
                bill payers? You can split the bills between you too!
              </p>
              <img src={BundleLogos} alt="Bundles" className="w-full max-w-sm object-cover" />
              <LinkButton href="/" target="_blank">
                Build my Home bundle
              </LinkButton>
            </div>
            <div className="flex flex-col items-center gap-5 border-t border-stone-200 py-10">
              <img
                src={EverythingToMakeItHome}
                alt="Everything to make it home"
                className="w-full max-w-sm"
              />
              <h4 className="max-w-sm text-center text-3xl font-medium">
                Everything you need to make it Home
              </h4>
              <p className="max-w-sm text-center">
                Make managing your home simple and rewarding, from utilities and services to
                cleaners and tradespeople.
              </p>
              <LinkButton href="/" target="_blank">
                View all home services
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-why flex flex-col gap-4 p-7 pb-8">
        <h4 className="text-2xl font-medium">My check list</h4>
        <ManualTask uiName="Set up utilities" />
        <ManualTask uiName="Set up broadband" />
        <ManualTask uiName="Set up water" />
        <ManualTask uiName="Set up council tax" />
        <ManualTask uiName="Set up mail forwarding" />
      </div>
    </>
  );
};

const Complete = () => (
  <>
    <div className="flex flex-col items-start gap-10 px-7 pb-12 md:grid md:grid-cols-2 md:gap-14 md:pb-8 md:pt-0">
      <div className="flex w-full max-w-xl flex-col gap-4">
        <h4 className="text-2xl font-medium">What to expect</h4>
        <p className="text-lg font-light">
          The big day! Your moment to finally relax as the conveyancing process has finished and you
          move out of Flat 4b, Fulham Road! Ownership of the property is transferred along with
          funds and you can hand over the keys.
        </p>
        <p className="text-lg font-light">
          Ownership of the property is transferred along with funds and you can hand over the keys.
          After that CDS Mayfair will then register the change of ownership with the Land Registry.
        </p>
        <a href="/" target="_blank" className="underline hover:no-underline" rel="noreferrer">
          Read our guide to completing
        </a>
      </div>
      <hr className="w-full border-stone-200 md:hidden" />
      <ConciergeReminderSignpost />
    </div>
    <div className="bg-why flex flex-col gap-4 p-7 pb-8">
      <h4 className="text-2xl font-medium">My check list</h4>
      <ManualTask uiName="Instruct a conveyancer" />
    </div>
  </>
);

export const SECTIONS = [
  {
    id: 'find-property',
    icon: <MapPin className="h-10 w-10 fill-primary" />,
    title: 'Find a property',
    content: <FindAProperty />,
    completion: 100,
  },
  {
    id: 'conveyancing',
    icon: <ConveyancingIcon className="h-10 w-10 fill-primary" />,
    title: 'Conveyancing',
    content: <Conveyancing />,
    completion: 100,
  },
  {
    id: 'finances',
    icon: <Pound className="h-10 w-10 fill-primary" />,
    title: 'Finances',
    content: <Finances />,
    completion: 100,
  },
  {
    id: 'insurance',
    icon: <InsuranceIcon className="h-10 w-10 fill-primary" />,
    title: 'Insurance',
    content: <Insurance />,
    completion: 100,
  },
  {
    id: 'exchange',
    icon: <Payment className="h-10 w-10 fill-primary" />,
    title: 'Exchange',
    content: <Exchange />,
    completion: 100,
  },
  {
    id: 'removals',
    icon: <RemovalsIcon className="h-10 w-10 fill-primary" />,
    title: 'Removals',
    content: <Removals />,
    completion: 100,
  },
  {
    id: 'move ready',
    icon: <Bulb className="h-10 w-10 fill-primary" />,
    title: 'Get move ready',
    content: <GetMoveReady />,
  },
  {
    id: 'complete',
    icon: <TickIsolated className="h-10 w-8 fill-primary" />,
    title: 'Complete',
    content: <Complete />,
  },
];
