import { LinkButton } from '@components/Button';

import { HouseCross } from '@images/icons';
import insuranceProviders from '@images/InsuranceProviders.webp';


type SignpostProps = {
  subtitle: string;
  title: string;
  price?: number;
  text?: string;
  image?: JSX.Element;
  buttonText?: string;
  buttonLink?: string;
  newTab?: boolean;
  children?: React.ReactNode;
};

export const Signpost = ({
  subtitle,
  title,
  price,
  image,
  buttonText,
  buttonLink,
  text,
  newTab,
  children,
}: SignpostProps) => {
  return (
    <div className="md:max-w-auto mx-auto flex w-full max-w-xl flex-col gap-5 rounded-3xl">
      <div className="flex w-full items-start justify-between gap-3">
        <div className="flex flex-col gap-2">
          <span className="font-bold">{subtitle}</span>
          <h4 className="text-2xl font-medium">
            {title}
            {!!price && (
              <>
                {' '}
                £{price}
                <span className="text-lg">.00</span>
              </>
            )}
          </h4>
        </div>
        <span className="flex h-12 w-12 min-w-12 items-center justify-center rounded-full bg-home">
          <HouseCross className="fill-white" height="28px" />
        </span>
      </div>
      <div className="flex w-full flex-col items-center gap-8">
        {text && <p className="text-left text-base">{text}</p>}
        {image && <div className="flex w-full justify-center">{image}</div>}

        {!!buttonLink && !!buttonText && (
          <LinkButton href={buttonLink} target={newTab ? '_blank' : ''}>
            {buttonText}
          </LinkButton>
        )}
      </div>
      {children}
    </div>
  );
};

export const BuyerProtectionSignpost = () => (
  <Signpost
    subtitle="Protect your move"
    title="Home Buyers Protection from"
    price={74}
    text="Did you know you can cover your legal, survey and mortgage costs should your purchase fall through."
    buttonText="Protect your move fees"
    buttonLink="https://home.rhinohomeprotect.com/quote/"
    newTab
  />
);

export const SellerProtectionSignpost = () => (
  <Signpost
    subtitle="Protect your move"
    title="Home Sellers Protection from"
    price={69}
    text="Did you know you can cover your legal costs should your sale fall through."
    buttonText="Protect your move fees"
    buttonLink="https://home.rhinohomeprotect.com/quote/"
    newTab
  />
);

export const EnergySignpost = () => (
  <Signpost
    subtitle="Get move ready"
    title="Save on your energy bills"
    text="Home can help find the perfect tariff for your place in less than 10 minutes, and even makes switching a breeze."
    buttonText="View energy tariffs"
    buttonLink="/wizard/energy/"
    newTab
  />
);

export const BroadbandSignpost = () => (
  <Signpost
    subtitle="Get move ready"
    title="Set up broadband"
    text="Find the right broadband deal for you and get set up in less than 5 minutes."
    buttonText="View broadband deals"
    buttonLink="/wizard/broadband/"
    newTab
  />
);

export const ConveyancingSignpost = ({ cost = 950 }: { cost?: number }) => (
  <Signpost
    subtitle="To do"
    title={`Instruct a conveyancer from £${cost}.00 and track your progress`}
    text={`Coneyancing for your sale from just £${cost}.00. View my quotes`}
    buttonText="View conveyancing quotes"
    buttonLink="#"
    newTab
  />
);

export const MortgageSignpost = () => (
  <Signpost
    subtitle="To do"
    title="Find your best mortgage deal, fast and hassle free"
    text="Access to thousands of mortgages from 70+ lenders without being charged a penny"
    buttonText="Find the best mortgage"
    buttonLink="#"
    newTab
  />
);

export const InsuranceSignpost = () => (
  <Signpost
    subtitle="To do"
    title="View quotes from over 50 UK insurance providers, including"
    image={<img src={insuranceProviders} alt="Insurance" className="max-w-80" />}
    buttonText="Get an instant quote"
    buttonLink="#"
    newTab
  />
);

export const InsuranceReminderSignpost = () => (
  <Signpost
    subtitle="Don't forget"
    title="You need to have buildings insurance in place before exchanging"
    buttonText="Get an instant quote"
    buttonLink="#"
    newTab
  />
);

export const RemovalsSignpost = ({ children }: { children: any }) => (
  <Signpost
    subtitle="To do"
    title="Book your removals"
    text="Home's got lorry loads of choice. Pick one of our reliable partners, and book them in. Easy."
  >
    {children}
  </Signpost>
);

export const ConciergeReminderSignpost = () => (
  <Signpost
    subtitle="Don't forget"
    title="We're on hand to help with anything you might need while moving and settling in"
    buttonText="Speak to your concierge"
    buttonLink="/"
  />
);
