export type ProductProps = {
  title: string;
  offerText?: string;
  image: string;
  price?: number;
  link: string;
  single?: boolean;
};

export const Product = ({ title, offerText, image, price, link, single }: ProductProps) => {
  const imageHeight = single ? 'pb-[50%]' : 'pb-[62%]';
  const variableStyles = single ? 'rounded-md' : '';

  return (
    <div
      key={title}
      className={`shadow-homeLargeLight relative flex w-full flex-col gap-4 overflow-hidden rounded-lg border border-gray-200 bg-white pb-5 text-primary ${variableStyles}`}
      data-testid="product"
    >
      {offerText && (
        <span className="absolute -top-2 left-2 rounded-full bg-primary px-2 py-1 text-xs font-medium text-white">
          {offerText}
        </span>
      )}
      <div
        className={`w-full bg-cover bg-center bg-no-repeat ${imageHeight}`}
        style={{ backgroundImage: `url(${image})` }}
      />
      <div className="flex flex-col items-center gap-3 px-4">
        <h3 className="text-center text-xl font-medium">{title}</h3>
        <div className="flex w-full items-center justify-between gap-2 font-medium">
          {price && <span>£{price}</span>}
          <a
            href={link}
            target="_blank"
            className="rounded-full bg-primary px-5 py-2 text-sm text-white"
            rel="noreferrer"
          >
            Buy now
          </a>
        </div>
      </div>
    </div>
  );
};
